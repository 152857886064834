@import "../_breakpoints";

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  // margin-top: 40px;
  width: 90%;
  @include media("<=desktop") {
    width: 90%;
  }
}

.image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.right-arrow {
  position: absolute;
  // top: 50%;
  right: 10px;
  font-size: 30px;
  color: #000;
  border-radius: 50%;
  padding: 10px;
  // margin: 40px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  @include media("<=desktop") {
    font-size: 20px;
    padding: 5px;
  }
}

.left-arrow {
  position: absolute;
  // top: 50%;
  left: 10px;
  font-size: 30px;
  color: #000;
  border-radius: 50%;
  padding: 10px;
  // margin: 40px;
  // z-index: 10;
  cursor: pointer;
  user-select: none;
  @include media("<=desktop") {
    font-size: 20px;
    padding: 5px;
  }
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
