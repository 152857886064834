@import "../_variables";

.header-container {
  background-color: $primaryColor;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 20px;
  &__apptive-logo {
    height: 50px;
    width: 80px;
    object-fit: contain;
  }
  span {
    color: $apptiveYellowColor;
    padding-left: 5px;
    font-size: 9pt;
    font-weight: 700;
  }
}
