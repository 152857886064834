@import "../__variables";

.button-container {
  border: 0;
  color: $white;
  padding: 8px 12px 8px 12px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 10pt;
  @include media("<=desktop") {
    font-size: 6pt;
    padding: 8px 10px 8px 10px;
  }
  &:hover {
    border: 1pt solid $white;
  }
}
