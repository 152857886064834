@import "./_variables";
@import "./__mixins";
// pages
@import "./pages/map.scss";
@import "./pages/no_match.scss";
@import "./pages/home.scss";
@import "./pages/error.scss";
@import "./pages/partner_details.scss";
// components
@import "./components/input.scss";
@import "./components/header.scss";
@import "./components/partner_item.scss";
@import "./components/marker.scss";
@import "./components/button.scss";
@import "./components/image_slider.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
