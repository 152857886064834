// colors
$primaryColor: #02223e;
$lessTransPrimaryColor: #0d395f;
$secondaryColor: #bdffde;
$apptiveYellowColor: #ffae43;
$black: #000000;
$white: #ffffff;
$gray: #dedede;

//sizes
$fullScreen: 92vh;

//breakpoints
$breakpoints: (
  "phone": 320px,
  "tablet": 768px,
  "desktop": 1024px,
) !default;
