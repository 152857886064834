@import "../_variables";
@import "../_breakpoints";
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Code+Latin:wght@300&display=swap");

.home-container {
  background-color: $primaryColor;
  height: $fullScreen;
  display: flex;
  flex-direction: column;
  &__welcome-title {
    color: $secondaryColor;
    font-size: xx-large;
    text-align: center;
  }
  &__list-of-component {
    display: flex;
    justify-content: center;
    @include media("<=desktop") {
      flex-direction: column;
    }
    ol {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      list-style: none;
      li {
        background-color: $lessTransPrimaryColor;
        border-radius: 5pt;
        @include media("<=desktop") {
          flex-direction: column;
        }
        font-size: x-large;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // flex-direction: column;
        cursor: pointer;
        margin: 10px;
        padding: 10px;
        a {
          text-decoration: none;
          color: $apptiveYellowColor;
          margin: 10px;
        }
        &:hover {
          background-color: $primaryColor;
          border-radius: 5pt;
          border: 1pt solid $apptiveYellowColor;
        }
      }
    }
  }
}

.url-style {
  font-family: "M PLUS Code Latin", sans-serif;
  color: $white;
  font-size: large;
  background-color: #5b5b5b;
  border: 1px solid $white;
  padding: 10px;
  border-radius: 5px;
  span {
    color: red;
  }

  @include media("<=desktop") {
    font-size: 6pt;
  }
}
