@import "../_variables";

.no-match-container {
  background-color: $primaryColor;
  height: $fullScreen;
  display: flex;
  justify-content: center;
  align-items: center;
  &__title {
    color: $secondaryColor;
    font-size: 20pt;
    font-weight: 600;
    .or {
      color: $apptiveYellowColor;
      font-size: 28pt;
    }
  }
}
