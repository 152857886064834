@import "../_variables";

.error-container {
  background-color: $primaryColor;
  height: $fullScreen;
  display: flex;
  justify-content: center;
  align-items: center;
  &__title {
    color: red;
    font-size: 20pt;
    font-weight: 600;
  }
}
