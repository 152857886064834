@import "../_variables";
@import "../_breakpoints";

.map-container {
  background-color: $primaryColor;
  height: 100vh;
  max-height: 100vh;
  display: flex;

  // list container
  &__list-container {
    box-sizing: content-box;
    min-width: 550px;
    display: block;
    background-color: $primaryColor;
    padding: 10px;
    flex: 0.3;
    @include media("<=desktop") {
      position: fixed;
      bottom: 0;
      // bottom: calc(580px - 100vh);
      left: 0;
      right: 0;
      z-index: 1000;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      min-width: 0;
    }
    // drag line
    &__drag-line-container {
      display: none;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 20px;
      &__drag-line {
        width: 30%;
        height: 8px;
        border-radius: 10px;
        background-color: $white;
      }
      @include media("<=desktop") {
        display: flex;
      }
    }
    // input container
    &__input-container {
      margin: 10px;
      display: flex;
      justify-content: space-between;
      @include media("<=desktop") {
        flex-direction: column;
      }
    }
    //list
    &__list {
      overflow-y: scroll;
      @include media(">desktop") {
        height: 90%;
      }
    }
  }
  // map
  &__map {
    flex: 0.7;
    @include media("<=desktop") {
      height: 100vh;
      flex: 1;
    }
  }
}

.show-list {
  @include media("<=desktop") {
    transition: all 0.3s ease-out;
    height: 90%;
  }
  &__list {
    @include media("<=desktop") {
      height: 80%;
    }
  }
}

.hide-list {
  @include media("<=desktop") {
    height: 38%;
    bottom: 0;
    transition: all 0.3s ease-out;
  }
  &__list {
    @include media("<=desktop") {
      height: 60%;
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
}
