@import "../_variables";
@import "../_mixins";

.input {
  border: 0;
  border-radius: 16px;
  flex: 0.45;
  padding: 4px;
  background-color: lighten($primaryColor, 10%);
  display: flex;
  align-items: center;
  margin: 5px 0 5px 0;
  min-width: 250px;
  &__icon {
    flex: 0.1;
  }
  input {
    border: 0;
    height: 35px;
    flex: 0.9;
    color: $white;
    background-color: transparent;
    @include placeholder {
      color: $white;
    }
    &:focus {
      outline: 0;
      outline-color: transparent;
      outline-style: none;
    }
  }
}
