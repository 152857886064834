@import "../__variables";
@import "../_breakpoints";

.partner-item-container {
  display: flex;
  flex-direction: row;
  background-color: lighten($primaryColor, 10%);
  margin: 10px;
  padding: 10px;
  border-radius: 16px;
  min-width: 250px;
  cursor: pointer;
  img {
    height: 80px;
    width: 80px;
    border-radius: 16px;
    object-fit: contain;
    background-color: lighten($white, 40%);
    padding: 10px;
    @include media("<=desktop") {
      height: 70px;
      width: 70px;
      padding: 4px;
    }
  }
  &__partner-info {
    flex: 3;
    padding: 10px;
    display: flex;
    flex-direction: column;
    @include media("<=desktop") {
      font-size: medium;
      padding: 4px;
    }
    &__partner-name {
      color: $white;
      font-weight: 600;
      font-size: large;
      @include media("<=desktop") {
        font-size: medium;
      }
    }
    &__partner-address {
      color: $gray;
      @include media("<=desktop") {
        font-size: small;
      }
    }
  }
  &__button-status-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.closed-text {
  color: red;
  display: flex;
  text-transform: uppercase;
  justify-content: flex-end;
  font-size: 8pt;
  text-align: center;
  @include media("<=desktop") {
    font-size: 6pt;
  }
}
.opening-hours-regarding-info-text {
  color: orange;
  display: flex;
  text-transform: uppercase;
  justify-content: flex-end;
  font-size: 8pt;
  text-align: center;
  @include media("<=desktop") {
    font-size: 6pt;
  }
}
.open-text {
  color: green;
  display: flex;
  justify-content: flex-end;
  font-size: 8pt;
  text-align: center;
  text-transform: uppercase;
  @include media("<=desktop") {
    font-size: 6pt;
  }
}
.selected-item {
  border: 1px solid $secondaryColor;
}
