@import "../_variables";

.marker-container {
  height: 40px;
  width: 40px;
  background-color: $secondaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid $gray;
  cursor: pointer;
  &__marker-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: contain;
  }
}

.selected-marker {
  animation: crescendo 0.5s alternate infinite ease-in;
}

@keyframes crescendo {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}
