@import "../_variables";
@import "../_breakpoints";

.main-container {
  min-height: 100vh;
}
.partner-details-container {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: row;
  // min-height: 40vh;
  &__partner-info {
    flex: 1;
    padding-right: 20px;
    &__description {
      padding: 10px;
    }
    @include media("<=desktop") {
      padding-right: 0px;
    }
  }

  &__slider {
    // height: 400px;
    margin: 10px;
    // background-color: red;

    // border-radius: 16px;
    // overflow: hidden;
    flex: 1;
    // justify-content: flex-start;
    // align-items: center;
    display: flex;
  }

  @include media("<=desktop") {
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 65px;
    // height: 650px;
    display: flex;
  }
}

.info-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  &__offers-container {
    flex: 1;
    &__offer-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
      &__text {
        padding-left: 10px;
      }
    }
  }
  &__links-container {
    flex: 1;
    &__link-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
      &__text {
        padding-left: 10px;
        text-decoration: none;
      }
    }
  }
  @include media("<=desktop") {
    flex-direction: column-reverse;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.roles-opening-hours-container {
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  &__roles-container {
    flex: 1;
    &__role-item {
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;
      &__text {
        padding-left: 10px;
      }
    }
  }
  &__opening-hours-container {
    flex: 1;
    &__opening-hours-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
      &__text {
        padding-left: 10px;
        text-decoration: none;
      }
      &__clock-container {
        flex: 3;
        display: flex;
        align-items: center;
      }
    }
  }
  @include media("<=desktop") {
    flex-direction: column-reverse;
    padding-left: 5%;
    padding-right: 5%;
    min-height: auto;
  }
}
.map-details-container {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__gallery {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    &__item {
      padding: 10px;
      margin: 10px;
      font-size: 30px;
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &__image {
        width: 100%;
        border-radius: 16px;
        object-fit: cover;
        &:hover {
          width: 200%;
        }
      }
    }
    @include media("<=desktop") {
      grid-template-columns: auto;
      padding: 0px;
    }
  }
}
.loader-container {
  height: 100vh;
  background-color: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.loader {
  border: 6px solid $secondaryColor; /* Light grey */
  border-top: 6px solid $apptiveYellowColor; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.title {
  font-size: 20pt;
  font-weight: 500;
}
// disable map strange buttons
.gmnoprint {
  display: none;
}
.gmnoprint {
  background: none !important;
}
#GMapsID {
  display: none;
}
